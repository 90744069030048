import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Loader from './Components/Loader';
import { useStore } from './Utils/Store';
import MyNavbar from './Components/Navbar';
import { Container } from 'react-bootstrap';

const PrivateRoute = () => {
  const location = useLocation();
  const token = useStore((state) => state.token);

  const isLogged = () => {
    if (token) {
      return true;
    }
    return false;
  };

  if (isLogged() !== true) {
    if (location.pathname === '/logout') {
      return <Navigate replace to="/login" />;
    }
    return <Navigate replace to="/login" state={{ from: location }} />;
  }
  return (
    <div className="App">
      <MyNavbar />
      <Container fluid className="mt-0 pt-0">
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Container>
    </div>
  );
};

export default PrivateRoute;
