import { BallTriangle } from 'react-loader-spinner';
//import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Loader = () => {
  return (
    <div className="centered">
      <BallTriangle height="100" width="100" color="red" ariaLabel="loading" />
    </div>
  );
};

export default Loader;
