import { lazy, Suspense, useEffect, useState } from 'react';
import './Assets/App.css';
import Loader from './Components/Loader';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Login from './Pages/Login';
import PrivateRoute from './PrivateRoute';

import axios from 'axios';
import { SERVICE_SERVER } from './Utils/Settings';
import { sleep } from './Utils/Helper';
import { ToastContainer } from 'react-toastify';
import { useStore } from './Utils/Store';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/app.scss';
import NotFound from './Pages/NotFound';

function App() {
  const Logout = lazy(() => import('./Pages/Logout'));
  const Licenses = lazy(() => import('./Pages/Licenses/List'));
  const AddLicense = lazy(() => import('./Pages/Licenses/Add'));
  const EditLicense = lazy(() => import('./Pages/Licenses/Edit'));
  const Devices = lazy(() => import('./Pages/Devices/List'));
  const Updaters = lazy(() => import('./Pages/Updaters'));

  const updateToken = useStore((state) => state.updateToken);
  const [AppIsReady, setAppIsReady] = useState(false);
  const queryClient = new QueryClient();
  const location = useLocation();
  const url = `${SERVICE_SERVER}/getLoginInfo`;

  useEffect(() => {
    if (location.pathname === '/login') {
      setAppIsReady(true);
    } else if (!AppIsReady) {
      axios
        .post(url)
        .then(async (response) => {
          const responseData = response.data;
          updateToken(responseData.token);
          await sleep(1000);
          setAppIsReady(true);
        })
        .catch(async () => {
          await sleep(1000);
          setAppIsReady(true);
        });
    }
  }, [AppIsReady, location, updateToken, url]);

  if (!AppIsReady) {
    return <Loader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="" element={<Navigate replace to="licenses" />} />
            <Route path="/licenses">
              <Route path="" element={<Licenses />}></Route>
              <Route path="add" element={<AddLicense />}></Route>
              <Route path="edit/:id" element={<EditLicense />}></Route>
            </Route>
            <Route path="/devices" element={<Devices />}></Route>
            <Route path="/updaters" element={<Updaters />}></Route>
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
        <ToastContainer />
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
