import { create } from 'zustand';

export type StoreType = {
  token: string;
  updateToken: (apiToken: string) => void;
};

export const useStore = create<StoreType>((set) => ({
  token: '',
  updateToken: (apiToken: string) => {
    set(() => ({
      token: apiToken,
    }));
  },
}));
