import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { SelectType } from '../Utils/Types';

type LoadOptions = (
  inputValue: string,
  callback: (options: any) => void
) => Promise<any> | void;

type InputProps = {
  id: string;
  type:
    | 'email'
    | 'password'
    | 'number'
    | 'datetime'
    | 'date'
    | 'time'
    | 'select'
    | 'asyncSelect'
    | 'textarea'
    | 'text'
    | 'textarea'
    | 'checkbox'
    | 'hidden';
  name: string;
  placeholder?: string;
  text: string;
  errors?: any;
  control: any;
  disabled?: boolean;
  readOnly?: boolean;
  selectOptions?: SelectType[];
  isMulti?: boolean;
  loadOptions?: LoadOptions;
  cacheOptions?: boolean;
  defaultOptions?: boolean;
  rows?: number;
};

const Input = ({
  id,
  type,
  name,
  placeholder,
  text,
  errors,
  control,
  disabled = false,
  readOnly = false,
  selectOptions,
  isMulti = false,
  loadOptions = undefined,
  cacheOptions = false,
  defaultOptions = false,
  rows = 3,
}: InputProps) => {
  const onInputChange = (inputValue: any) => {};
  const renderControl = ({ field }: any) => {
    if (type === 'select') {
      const animatedComponents = makeAnimated();
      const { onChange, value, ...rest } = field;
      let selectedValue: SelectType[] | SelectType | undefined;
      if (isMulti) {
        selectedValue = selectOptions?.filter((c) => value.includes(c.value));
      } else {
        selectedValue = selectOptions?.find((c) => c.value === value);
      }
      return (
        <Select
          {...rest}
          className={errors[name] ? 'is-invalid' : ''}
          options={selectOptions}
          value={selectedValue}
          isMulti={isMulti}
          components={animatedComponents}
          onChange={(val: any) => {
            if (isMulti) {
              onChange(val.map((v: SelectType) => v.value));
            } else {
              onChange(val.value);
            }
          }}
        />
      );
    }
    if (type === 'asyncSelect') {
      const animatedComponents = makeAnimated();
      return (
        <AsyncSelect
          {...field}
          className={errors[name] ? 'is-invalid' : ''}
          components={animatedComponents}
          loadOptions={loadOptions}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          onInputChange={onInputChange}
        />
      );
    }
    if (type === 'textarea') {
      return (
        <Form.Control
          isInvalid={!!errors?.[name]}
          as={type}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          rows={rows}
          {...field}
        />
      );
    }
    return (
      <Form.Control
        isInvalid={!!errors?.[name]}
        type={type}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        {...field}
      />
    );
  };

  if (type === 'hidden') {
    return <Controller name={name} control={control} render={renderControl} />;
  }
  return (
    <Form.Group>
      <Form.Label htmlFor={id}>{text}</Form.Label>
      <Controller name={name} control={control} render={renderControl} />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => (
          <Form.Control.Feedback type="invalid">
            {message}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
};

export default Input;
