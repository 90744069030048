import Button, { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const icons: any = {
  facebook: 'fab fa-facebook',
  google: 'fab fa-google',
  googlePlus: 'fab fa-google-plus',
  add: 'fas fa-plus',
};

export interface AppButtonProps extends ButtonProps {
  children: any;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: string;
  theme?: 'primary' | 'secondary' | 'success' | 'warning' | 'info';  
}

const AppButton = ({
  children,
  isLoading,
  icon,
  theme = 'primary',
  type = 'button',
  disabled,
  ...otherProps
}: AppButtonProps) => {
  let spinnerTemplate;
  let iconTemplate;

  if (isLoading) {
    spinnerTemplate = (
      <Spinner
        className="ml-2"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }

  if (icon && icons[icon]) {
    iconTemplate = <i className={`${icons[icon]} mr-2`} />;
  }

  return (
    <Button
      {...otherProps}
      type={type}
      variant={theme}
      disabled={isLoading || disabled}
    >
      {iconTemplate}
      {children}
      {spinnerTemplate}
    </Button>
  );
};

export default AppButton;
