import { Link } from 'react-router-dom';
import '../Assets/Navbar.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';

export default function MyNavbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => setMenuOpen(false);
  const expand = false;
  return (
    <Navbar bg="light" expand={expand} className="mb-2" sticky="top">
      <Container fluid>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          onClick={toggleMenu}
        />
        <Navbar.Brand href="/">
          MedConnect
          <img
            src={require('../Assets/Images/logo-small.png')}
            className="Logo"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          show={menuOpen}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              MedConnect
              <img
                src={require('../Assets/Images/logo-small.png')}
                className="Logo"
                alt="logo"
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Link className="nav-link" to="/licenses" onClick={toggleMenu}>
                Licenses
              </Link>
              <Link className="nav-link" to="/devices" onClick={toggleMenu}>
                Devices
              </Link>
              <Link className="nav-link" to="/updaters" onClick={toggleMenu}>
                Updaters
              </Link>
              <Link className="nav-link" to="/logout" onClick={toggleMenu}>
                Logout
              </Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
